<template>
  <div class="order-form">
    <!-- 配送信息 -->
    <div class="delivery" v-if="enabled" @click="selectAddress">
      <div class="van-cell van-cell--center van-cell--clickable van-cell--borderless van-contact-card van-contact-card--add" v-if="JSON.stringify(delivery) == '{}'">
        <van-icon name="location-o" class="left-icon" />
        <div class="van-cell__value van-cell__value--alone van-contact-card__value add-contacts">添加联系人</div>
        <van-icon name="arrow" class="right-icon" />
      </div>
      <div class="van-cell van-cell--center van-cell--clickable van-cell--borderless van-contact-card" v-else>
        <van-icon name="location-o" class="left-icon" />
        <div class="van-cell__value van-cell__value--alone van-contact-card__value">
          <div class="header"><span class="name">{{delivery.conName}}</span> <span class="tel">{{delivery.conMobile}}</span></div>
          <div class="address van-multi-ellipsis--l2">{{delivery.address}}</div>
        </div>
        <van-icon name="arrow" class="right-icon" />
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="goods">
      <header>

      </header>
      <div class="content">
        <van-card
          num="1"
          price="2.00"
          title="《西游记》孙教授批注版"
          thumb="https://img.yzcdn.cn/vant/ipad.jpeg"
        >
          <template #title>
            <van-tag  type="primary">{{goods.name}}</van-tag>
          </template>
          <template #price>
           <van-tag  plain type="primary">{{goods.price}}</van-tag>
          </template>
          <template #thumb>
            <img :src="goods.cover" style="width: 80px;height: 109px;"/>
          </template>
          <!--<template  #num>-->
            <!--<van-stepper @change="onChange" v-model="quantity" button-size="22" disable-input min="1" max="99" />-->
          <!--</template>-->
        </van-card>
        <!-- <van-card
          num="1"
          price="5.00"
          origin-price="8.00"
          desc="选项: 下册"
          title="<<西游记>>孙教授批注版"
          thumb="https://img.yzcdn.cn/vant/ipad.jpeg"
        /> -->
        <div class="van-cell" v-if="goods.delivery">
          <span class="label">配送费用</span>
          <span class="value">包邮</span>
        </div>
        <!--<div class="van-cell">-->
          <!--<span class="label">商店优惠</span>-->
          <!--<span class="value disabled">没有可用优惠</span>-->
        <!--</div>-->
        <div class="van-cell">
          <!--<span class="label">订单总金额</span>-->
          <span v-if="type==1" class="value price">￥{{goods.totalPrice}}</span>
          <span v-if="type==2" class="value price">
            <img src="../assets/images/coin.png"/>
            {{goods.totalPrice*100}}</span>
        </div>
      </div>

    </div>
    <!-- 备注信息 -->
    <!--<div class="notes">-->
      <!--<van-cell title="备注信息" is-link :value="remarks ? remarks : '选项，建议先和商家沟通后再填写'" @click="showMessage" />-->
      <!--&lt;!&ndash; 备注修改界面 &ndash;&gt;-->
      <!--<van-dialog v-model:show="show" show-cancel-button :beforeClose="beforeClose">-->
        <!--<div class="header">买家留言</div>-->
        <!--<div>-->
          <!--<van-field-->
              <!--v-model="message"-->
              <!--rows="4"-->
              <!--label-width="0"-->
              <!--autosize-->
              <!--type="textarea"-->
              <!--maxlength="50"-->
              <!--placeholder="请输入买家留言"-->
              <!--show-word-limit-->
            <!--/>-->
        <!--</div>-->
      <!--</van-dialog>-->
    <!--</div>-->

    <van-submit-bar :price="goods.totalPrice*100" decimal-length="2" button-text="提交订单" @submit="onSubmit">
      <span>共 {{quantity}} 件，</span>
    </van-submit-bar>

  </div>
</template>

<script>
import { ref,onActivated} from 'vue';
import { showToast  } from "vant";
import { useRouter } from 'vue-router';
import { orderInfo,wxPay,getSignature } from '@/service/course'
import wx from "weixin-js-sdk"
export default {
  setup() {
    const router = useRouter();
    const enabled = ref(true); // 开启配送
    const delivery = ref({}); // 配送信息
    const goods = ref({});  // 商品信息
    const remarks = ref('');  // 订单备注
    const quantity = ref(1);  // 商品数量
    const show = ref(false); // 是否显示备注修改界面
    const message = ref('');  // 备注信息
    const goodsId= ref('');
    const pay=ref({});
    const wechat=ref({});
    const storage= localStorage.getItem("token-hyxt");
    const token=JSON.parse(storage);
    onActivated(()=>{
      const url=location.href.split('#')[0];
      wechatConfig(url);
      const address = router.currentRoute.value.query.address;//收获地址
      goodsId.value=router.currentRoute.value.query.goodsId;//商品id
      const conName = router.currentRoute.value.query.conName;//联系人
      const conMobile=router.currentRoute.value.query.conMobile;//联系电话
      delivery.value.address=address;
      delivery.value.conName=conName;
      delivery.value.conMobile=conMobile;
      orderInfo(goodsId.value,token.userId).then(res =>{
        goods.value.name=res.data.data.goodsVo.name;
        goods.value.cover=res.data.data.goodsVo.cover;
        goods.value.price=res.data.data.goodsVo.marketPrice;
        goods.value.totalPrice=res.data.data.goodsVo.marketPrice;
        goods.value.delivery=res.data.data.goodsVo.delivery;
        if(!delivery.value.address){
          //地址为空，从后台返回
          delivery.value.address=res.data.data.address.area+" "+res.data.data.address.detail;
          delivery.value.conName=res.data.data.address.conName;
          delivery.value.conMobile=res.data.data.address.conMobile;
        }
      })
    })
     //获取微信配置信息
    const wechatConfig=(url)=>{
      getSignature({url:url}).then(res=>{
        wechat.value=res.data;
      })
    }

    // 提交订单
    const onSubmit = () => {
      if(enabled.value && JSON.stringify(delivery.value) == '{}') {
        showToast ('请选取配送信息');
        return;
      }else{
          //购买课程
          //支付
          if(goods.value.delivery){
            //需要配送
            pay.value.conName=delivery.value.conName;
            pay.value.conMobile=delivery.value.conMobile;
            pay.value.address=delivery.value.address;
          }
          pay.value.userId=token.userId;
          pay.value.openId=token.openId;
          pay.value.coursePackId=goodsId.value;
          pay.value.quantity=quantity.value;
          wxPay({openId:pay.value.openId,coursePackId:pay.value.coursePackId,quantity:pay.value.quantity,userId:pay.value.userId,conName:pay.value.conName,conMobile:pay.value.conMobile,address:pay.value.address}).then(res=>{
            const result=res.data.data;
            if(1==result.code){
              showToast({
                message:"您已拥有该音频，即将前往书城购买此配套书籍",
                duration:5000
              })
              router.push({path:"/store"});


            }else{
              wx.config({
                debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: wechat.value.appId, // 必填，公众号的唯一标识
                timestamp: wechat.value.timestamp, // 必填，生成签名的时间戳
                nonceStr: wechat.value.noncestr, // 必填，生成签名的随机串
                signature: wechat.value.signature, // 必填，签名，见附录1
                jsApiList: ['checkJsApi', 'chooseWXPay']
                // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              });

              wx.ready(function () {
                wx.chooseWXPay({
                  appId: result.appId,
                  timestamp: result.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                  nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
                  package: result.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
                  signType: result.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                  paySign: result.paySign, // 支付签名
                  success:function () {
                    //支付成功后的回调
                    showToast ('支付成功')
                    router.push({path:"/category"});
                  },
                  fail:function () {
                    showToast ('支付失败')
                  }
                })
              })
            }

          })









      }
    }
    //计数器
    // const onChange=(value)=>{
    //   quantity.value=value++;
    //   goods.value.totalPrice=quantity.value*goods.value.price;
    //
    // }
    // 显示地址列表
    const selectAddress = () => {
      // router.push({name: 'address', params: { from: 'course' }});
      router.push({path: '/address', query: {goodsId:goodsId.value}});
    }
    // 显示备注修改
    const showMessage = () => {
      message.value = remarks.value ? remarks.value : "";
      show.value = true;
    }
    // 关闭备注修改
    const beforeClose = (action) => {
      if(action === "confirm") {
        remarks.value = message.value;
      }
      show.value = false;
    }


    return {
      goods,
      remarks,
      show,
      enabled,
      delivery,
      onSubmit,
      message,
      showMessage,
      beforeClose,
      selectAddress,
    }
  }
}
</script>

<style lang="scss">
  .order-form {
    .delivery {
      .left-icon, .right-icon {
        height: var(--van-cell-line-height);
        font-size: var(--van-cell-icon-size);
        line-height: var(--van-cell-line-height);
      }
      .left-icon {
        margin-right: var(--van-padding-base);
        // color: var(--van-contact-card-add-icon-color);
        // font-size: var(--van-contact-card-add-icon-size);
      }
      .right-icon {
        margin-left: var(--van-padding-base);
        color: var(--van-cell-right-icon-color);
      }
      .add-contacts {
        color: #666;
      }
      .address {
        font-size: 24px;
        line-height: 24px;
        color: var(--van-cell-right-icon-color);
      }
    }
    .goods {
      .content {
        padding: 16px 0;
        background-color: #fff;
        .van-card {
          background-color: #fff;
          .van-card__price {
            color: red;
          }
        }
        .van-cell {
          .label {
            flex: 1;
            color: var(--van-cell-value-color);
          }
          .value {
            flex: 1;
            text-align: right;
            color: red;
          }
          >.price {
            font-size: var(--van-card-price-integer-font-size);
            font-weight: var(--van-font-weight-bold);
            font-family: var(--van-card-price-font-family);
            color: red;
          }
          >.disabled {
            color: var(--van-cell-value-color);
          }
        }
      }
    }
    .notes {
      margin-top: 30px;
      .van-cell__title {
        flex: inherit;
        color: var(--van-cell-value-color);
      }
      .van-cell__value {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .header {
        line-height: 40px;
        font-weight: 500;
        padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding) 0px;
      }
      textarea {
        background-color: #f7f8fa;
      }
    }
    // .delivery {
    //   position: relative;
    //   background-color: #fff;
    //   // padding: var(--van-contact-card-padding);
    //   .van-cell {
    //     display: flex;
    //     align-items: center;
    //     .add-icon {
    //       color: var(--van-contact-card-add-icon-color);
    //       font-size: var(--van-contact-card-add-icon-size);
    //     }
    //   }

    //   &::before {
    //     position: absolute;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     height: 2px;
    //     background: repeating-linear-gradient(-45deg,var(--van-warning-color) 0,var(--van-warning-color) 20%,transparent 0,transparent 25%,var(--van-primary-color) 0,var(--van-primary-color) 45%,transparent 0,transparent 50%);
    //     background-size: 80px;
    //     content: "";
    //   }
    // }
  }
</style>
